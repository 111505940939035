<template>
    <div class="dashboard">
        <div class="w3-main" >
            <div style="padding-right:20px;" >
                <div class="w3-row" style="padding-top:50px;">                
                    <div v-bind:style="'height:'+  (screenHeight-133) +'px;'">
                        <div class="w3-modal-content w3-animate-zoom w3-card-4" style="max-width:550px;background-color:#1C4672;">   
                            <header class="w3-container w3-theme-d3 drag"> 
                                <h6><i class="fa fa-windows"></i> Profile</h6>
                            </header>
                            <div class="w3-container w3-padding w3-light-blue">
                                <form method="post" id="frmKaryawan" v-on:submit.prevent="saveData" action="#" autocomplete="off">
                                    <div class="w3-row" style="margin-top:5px;">
                                        <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                            <label for="u_email">User Name :</label>
                                        </div>
                                        <div class="w3-col l9 m9 s12">
                                            <input required type="text" class="w3-input w3-small" id="u_email" placeholder="Email" v-model="rec.u_email">
                                        </div>
                                    </div>                        
                                    <div class="w3-row" style="margin-top:5px;">
                                        <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                            <label for="u_fname">Nama Depan :</label>
                                        </div>
                                        <div class="w3-col l9 m9 s12">
                                            <input required type="text" class="w3-input w3-small" id="u_fname" placeholder="FNama" v-model="rec.u_fname">
                                        </div>
                                    </div>
                                    <div class="w3-row" style="margin-top:5px;">
                                        <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                            <label for="u_lname">Nama Belakang :</label>
                                        </div>
                                        <div class="w3-col l9 m9 s12">
                                            <input required type="text" class="w3-input w3-small" id="u_lname" placeholder="LNama" v-model="rec.u_lname">
                                        </div>
                                    </div>
                                    <div class="w3-row" style="margin-top:5px;">
                                        <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                            <label for="u_password">Password :</label>
                                        </div>
                                        <div class="w3-col l9 m9 s12" style="position: relative;">
                                            <input :type="lihat?'text':'password'" class="w3-input w3-small" id="pass" placeholder="Password" v-model="rec.pass">
                                            <button v-on:click="lihat=!lihat" type="button" class="w3-btn w3-text-black" style="position: absolute;top: 0;right: 0;"><i class="fa" :class="lihat?'fa-eye':'fa-eye-slash'"></i></button>
                                            <span style="color:red;font-size:10px">isikan bila ganti password pada edit</span>
                                        </div>
                                    </div>                                
                                </form>
                            </div>
                            <footer class="w3-container w3-theme-l1">
                                <h6 class="w3-right">
                                    <button form="frmKaryawan" type="submit" class="w3-btn w3-small w3-theme-d5" style="margin-right:5px;"><i class="fa fa-save"></i> Save</button>
                                </h6>
                            </footer>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import md5 from "@/assets/doevent/md5.js";
export default {    
    head: {
        title: {
          inner: "Profile"
        }
    },
    name: "Profile",
    components: {
        
    },
    data() {
        return {
            lihat : false,
            screenHeight : 0,
            rec : {},
            fieldRules : []
        }
    },
    methods : {
        loadData(){
            let self = this;            
            let vparams = {
                "operator" :"AND",
                "kondisi" : "=",
                "field": "u_email" ,
                "fieldvalue": localStorage.username,
                "limit": "1",
                "offset" : "0",
            };
            console.log(vparams);
            self.dePost("/tproject/users/search",vparams,function(response){
                if(response.data.success==true){
                    self.rec = response.data.rec[0];
                    self.rec["pass"] = "";
                }else{
                    console.log('Gagal');
                }
            });
        },
        saveData(event){
            const self = this;
            if(self.rec.pass!=''){
                self.rec.u_password = md5(self.rec.pass);
            }else{
                self.rec.u_password = "";
            }
            self.dePost('/tproject/users/edit',self.rec,function(response){
                if(response.data.success==true){
                    self.$toast.success("User berhasil di udpate");
                    // swal({title: "Save",text: "Sukses Simpan data",type: "success", html : true, timer : 2000});
                }else{
                    self.$toast.error(response.data.msg);
                    // swal({title: "Save",text: "Gagal simpan data",type: "error", html : true});
                }
            });
            event.preventDefault();
            return false;
        },
    },
    mounted(){
        this.loadData();
        this.screenHeight = window.innerHeight;
    }
};
</script>
<style>
#box{
    overflow-y:scroll;    
}
#tbl-content{
    table-layout:fixed;
}
</style>